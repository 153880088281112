<template>
  <div>
    <section class="download-head">
      <div class="download-head-inner">
        <div class="container">
          <div class="download-head-img-wrap">
            <b-img
              class="download-head-img"
              :src="`${publicPath}assets/images/download-head.png`"
              fluid
              alt=""
            ></b-img>
            <div class="download-head-img-lists">
              <img
                v-for="(album, index) in albums"
                :src="album"
                :key="index"
                alt=""
              />
            </div>
          </div>
          <div class="download-head-content">
            <h1 class="head-title">
              <span
                v-html="$t(`downloadPage.elevatingTheMusicExperience`)"
              ></span>
            </h1>
            <div
              class="download-head-subtitle"
              v-html="$t(`downloadPage.trebelGivesYouFree`)"
            ></div>
            <UiSocial></UiSocial>
          </div>
        </div>
      </div>
    </section>
    <section class="download-features">
      <h2 class="section-title">{{ $t("downloadPage.features") }}</h2>
      <div class="container">
        <b-row class="features-list">
          <b-col cols="6" md>
            <div class="features-item">
              <div class="features-icon">
                <b-img
                  :src="`${publicPath}assets/images/icon-features-song-id.svg`"
                  fluid
                  alt=""
                  class="icon-features-song-id"
                ></b-img>
              </div>
              <h3 class="features-title">{{ $t("downloadPage.songID") }}</h3>
              <div class="features-text">
                {{ $t("downloadPage.identifyTheSongs") }}
              </div>
            </div>
          </b-col>
          <b-col cols="6" md>
            <div class="features-item">
              <div class="features-icon">
                <b-img
                  :src="`${publicPath}assets/images/icon-features-offline.svg`"
                  fluid
                  alt=""
                  class="icon-features-offline"
                ></b-img>
              </div>
              <h3 class="features-title">{{ $t("downloadPage.offline") }}</h3>
              <div class="features-text">
                {{ $t("downloadPage.listenAnywhere") }}
              </div>
            </div>
          </b-col>
          <b-col cols="6" md>
            <div class="features-item">
              <div class="features-icon">
                <b-img
                  :src="
                    `${publicPath}assets/images/icon-features-listening.svg`
                  "
                  fluid
                  alt=""
                  class="icon-features-listening"
                ></b-img>
              </div>
              <h3 class="features-title">
                {{ $t("downloadPage.onDemandListening") }}
              </h3>
              <div class="features-text">
                {{ $t("downloadPage.anySongAnytime") }}
              </div>
            </div>
          </b-col>
          <b-col cols="6" md>
            <div class="features-item">
              <div class="features-icon">
                <b-img
                  :src="`${publicPath}assets/images/icon-features-skips.svg`"
                  fluid
                  alt=""
                  class="icon-features-skips"
                ></b-img>
              </div>
              <h3 class="features-title">
                {{ $t("downloadPage.unlimitedSkips") }}
              </h3>
              <div class="features-text">
                {{ $t("downloadPage.chooseAnySong") }}
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="features-item">
              <div class="features-icon">
                <b-img
                  :src="
                    `${publicPath}assets/images/icon-features-offscreen.svg`
                  "
                  fluid
                  alt=""
                  class="icon-features-offscreen"
                ></b-img>
              </div>
              <h3 class="features-title">{{ $t("downloadPage.offscreen") }}</h3>
              <div class="features-text">
                {{ $t("downloadPage.multitask") }}
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </section>
    <section class="download-top-questions">
      <h2 class="section-title">{{ $t("downloadPage.topQuestions") }}</h2>
      <div class="container">
        <div class="list-items-wrap">
          <ul class="list-items">
            <li>
              <div class="list-item-title">
                {{ $t("downloadPage.isTrebelLegal") }}
              </div>
              <div
                class="list-item-text"
                v-html="$t('downloadPage.yesSinceInception')"
              ></div>
            </li>
            <li>
              <div class="list-item-title">
                {{ $t("downloadPage.isTrebelFree") }}
              </div>
              <div
                class="list-item-text"
                v-html="$t('downloadPage.yesTrebelIsSupported')"
              ></div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import UiSocial from "@/components/UI/UiSocial.vue";
export default {
  name: "Download",
  metaInfo() {
    return {
      title: this.$t("meta.download.title"),
      titleTemplate: null,
      htmlAttrs: {
        lang: this.$t("lang")
      },
      meta: this.generateMeta({
        title: this.$t("meta.download.title"),
        description: this.$t("meta.download.description"),
        url: this.generateUrl(this.locale)
      })
    };
  },
  components: {
    UiSocial
  },
  computed: {
    locale() {
      return this.$store.getters.locale;
    }
  },
  data() {
    return {
      albums: [
        `${this.publicPath}assets/images/album/album-1.png`,
        `${this.publicPath}assets/images/album/album-2.png`,
        `${this.publicPath}assets/images/album/album-3.png`,
        `${this.publicPath}assets/images/album/album-4.png`,
        `${this.publicPath}assets/images/album/album-5.png`,
        `${this.publicPath}assets/images/album/album-6.png`,
        `${this.publicPath}assets/images/album/album-7.png`,
        `${this.publicPath}assets/images/album/album-8.png`
      ]
    };
  }
};
</script>
