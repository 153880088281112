<template>
  <ul class="stores-list">
    <li v-if="_isBrowser || (_isMobile && _isAndroid && !_isHuawei)">
      <a :href="getStoreUrl('google-play')" target="_blank">
        <img
          :src="
            `${publicPath}assets/images/google-play-badge-${$i18n.locale}.png`
          "
          alt=""
        />
      </a>
    </li>
    <li v-if="_isBrowser || (_isMobile && _isIOS)">
      <a :href="getStoreUrl('app-store')" target="_blank">
        <img
          :src="
            `${publicPath}assets/images/app-store-badge-${$i18n.locale}.png`
          "
          alt=""
        />
      </a>
    </li>
    <li v-if="_isBrowser || (_isMobile && _isHuawei)">
      <a :href="getStoreUrl('app-gallery')" target="_blank">
        <img
          :src="
            `${publicPath}assets/images/app-gallery-badge-${$i18n.locale}.png`
          "
          alt=""
        />
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  methods: {
    getStoreUrl(store) {
      if (
        this._isMobile &&
        (this._isAndroid || this._isIOS) &&
        !this._isHuawei
      ) {
        return "https://app.adjust.com/beeem3i_kqh0qok?campaign={boost_id}&adgroup={widget_id}&creative={content_id}&ip_address={ip_address}";
      }
      if (store === "google-play") {
        return this.$i18n.locale === "us"
          ? "https://play.google.com/store/apps/details?id=com.mmm.trebelmusic&hl=en_US"
          : "https://play.google.com/store/apps/details?id=com.mmm.trebelmusic&hl=es_MX";
      }

      if (store === "app-store") {
        return this.$i18n.locale === "us"
          ? "https://itunes.apple.com/us/app/trebel-music-song-downloader/id912390687?mt=8"
          : "https://itunes.apple.com/mx/app/trebel-music-descarga-m%C3%BAsica/id912390687?mt=8";
      }

      if (store === "app-gallery") {
        return this.$i18n.locale === "us"
          ? "https://appgallery.huawei.com/#/app/C103535497"
          : "https://appgallery.huawei.com/#/app/C103535497";
      }
    }
  }
};
</script>
